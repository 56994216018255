import {Component, OnInit} from '@angular/core';
import {Chart} from 'chart.js';

@Component({
  selector: 'app-half-doughnut-chart',
  templateUrl: './half-doughnut-chart.component.html',
  styleUrls: ['./half-doughnut-chart.component.scss']
})
export class HalfDoughnutChartComponent implements OnInit {
  percent = '50%';
  currentDate = new Date();
  startDate = (new Date()).setDate(this.currentDate.getDate() - 60);
  endDate = (new Date()).setDate(this.currentDate.getDate() - 30);


  constructor() {
  }

  ngOnInit() {
    const halfDoughnut = document.getElementById('halfDoughnut') as HTMLCanvasElement;

    const chart = new Chart(halfDoughnut, {
      type: 'doughnut',
      data: {
        labels: ['Gray', 'Yellow'],
        datasets: [{
          label: '# of Votes',
          data: [50, 50],
          backgroundColor: [
            '#FFCE4F',
            '#C8C8C8'
          ],
          datalabels: {
            display: false    //  krije vrednosti u grafiku
          }
        }]
      },
      options: {
        rotation: 1 * Math.PI,
        circumference: 1 * Math.PI,
        cutoutPercentage: 72,
        legend: {
          display: false
        }
      }
    });
  }

}
