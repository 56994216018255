import {Component, OnInit, ViewChild, TemplateRef, ViewContainerRef, ViewEncapsulation, Input} from '@angular/core';
import {Overlay, CdkOverlayOrigin, OverlayConfig} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {MatCalendarCellCssClasses} from '@angular/material';
import * as _moment from 'moment';
import {extendMoment} from 'moment-range';
import {FormControl} from '@angular/forms';
import {CustomerService} from '../../../services/customer.service';

const moment = extendMoment(_moment);

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './range-picker.component.html',
  styleUrls: ['./range-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DateRangePickerComponent implements OnInit {

  constructor(
    public overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
  ) {
  }

  @ViewChild(CdkOverlayOrigin, {static: true}) _overlayOrigin: CdkOverlayOrigin;
  @ViewChild(TemplateRef, {static: true}) content: TemplateRef<any>;
  @Input() dateTimeRangeForm: FormControl;
  @Input() closePanel: (data: any) => {};
  @Input() panelClass: string;

  template: TemplatePortal<any>;
  dateFormat = 'DD.MM.YYYY';

  overlayRef: any;

  selectedDateFrom = moment(new Date()).format();
  selectedDateTo = moment(this.selectedDateFrom).add(-7, 'days').format();
  months = [moment(new Date()).add(-1, 'months').format(), moment(new Date()).format()];

  selectedRangeDate = [];
  selectedRange: any = {};

  from = false;
  minDate = null;

  ngOnInit() {
    if (this.dateTimeRangeForm.value) {
      const range = this.dateTimeRangeForm.value.split(' - ');
      this.selectedDateFrom = range[0];
      this.selectedDateTo = range[1];
    }

    this.getRangeDate();
    // this.setTime();
    this.setDate();
  }

  onSelectedChange(event) {
    if (this.from) {
      this.selectedDateFrom = moment(event).format();
    } else {
      this.selectedDateTo = moment(event).format();
    }
    this.from = !this.from;
    this.getRangeDate();
  }

  setDate(from = this.selectedDateFrom, to = this.selectedDateTo) {
    if (from > to) {
      [from, to] = [to, from];
    }
    this.selectedRange.from = moment(from).format(this.dateFormat);
    this.selectedRange.to = moment(to).format(this.dateFormat);
    this.updateDateTimeRangeForm();
  }

  updateDateTimeRangeForm() {
    // tslint:disable-next-line:max-line-length
    const from = moment(this.selectedRange.from, ['DD.MM.YYYY']).format('DD.MM.YY');   // obrisan format hh:mm iza DD.MM.YY, da ne prikazuje u inputu (zatvoren panel)
    const to = moment(this.selectedRange.to, ['DD.MM.YYYY']).format('DD.MM.YY');
    this.dateTimeRangeForm.patchValue(`${from} - ${to}`);
  }

  getRangeDate() {
    this.minDate = new Date('1970/01/01'); // any date, only to force rendering

    if (this.selectedDateFrom && this.selectedDateTo) {
      const check = this.selectedDateFrom < this.selectedDateTo ? true : false;
      const fromTo: any = {
        from: check ? this.selectedDateFrom : this.selectedDateTo,
        to: !check ? this.selectedDateFrom : this.selectedDateTo,
      };
      const range = moment.range(fromTo.from, fromTo.to);
      this.selectedRangeDate = Array.from(range.by('days')).map(m => m.format());
      this.setDate(fromTo.from, fromTo.to);
    } else {
      this.selectedRangeDate = [];
    }

    setTimeout(() => {
      this.minDate = null; // Set null to remove the date restriction
    }, 0); // Wait to change-detection function has terminated to execute a new change to force rendering the rows and cells
  }

  openPicker() {
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(this._overlayOrigin.elementRef)
      .withPositions([{
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      }]);

    const config = new OverlayConfig({
      positionStrategy,
      hasBackdrop: true,
      panelClass: [this.panelClass, 'range-picker-panel'],
      backdropClass: 'cdk-overlay-transparent-backdrop',
    });


    this.overlayRef = this.overlay.create(config);


    // this.overlayRef.backdropClick().subscribe(() => {      // ovo sluzi da pokupi posalje podatke na klik izvan kalendara(overlay-a)
    //   this.collectDate();
    // });

    this.template = new TemplatePortal(this.content, this.viewContainerRef);

    this.overlayRef.attach(this.template);
  }

  collectDate() {
    this.closePanel({
      // tslint:disable-next-line:max-line-length
      from: moment(this.selectedRange.from, ['DD.MM.YYYY HH:mm']).format('YYYY-MM-DD'),  // obrisano format hh:mm- ne prikazuje u otvorenom panelu pored datuma
      to: moment(this.selectedRange.to, ['DD.MM.YYYY HH:mm']).format('YYYY-MM-DD'),

    });
    this.overlayRef.dispose();
  }

  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      let cssClass = '';
      const length = this.selectedRangeDate.length - 1;

      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.selectedRangeDate.length; i++) {
        if (moment(date).format(this.dateFormat) === moment(this.selectedRangeDate[i]).format(this.dateFormat)) {
          if (i === 0) {
            cssClass = 'mat-calendar-body-selected selected-start';
          } else if (i === length) {
            cssClass = 'mat-calendar-body-selected selected-end';
          } else {
            cssClass = 'mat-calendar-body-highlighte';
          }
          break;
        }
      }

      return cssClass;
    };
  }

  nextMonth() {
    const current = moment(this.months[0]).add(1, 'months').format();
    const next = moment(this.months[1]).add(1, 'months').format();
    this.months = [current, next];
  }

  previewMonth() {
    const current = moment(this.months[0]).subtract(1, 'months').format();
    const next = moment(this.months[1]).subtract(1, 'months').format();
    this.months = [current, next];
  }

  monthSelected(event, i) {
    this.months[i] = moment(event).format();
  }
}
