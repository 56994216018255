import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {RouterLinkActive} from '@angular/router';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit, OnChanges {
  @Input() state: boolean;

  // @Output() stateChanged = new EventEmitter<boolean>();

  constructor() {
  }

  @ViewChild(RouterLinkActive, {static: false}) private routerLinkActive: RouterLinkActive;

  ngOnInit() {
    // this.active = JSON.parse(localStorage.isOpened || '{}');  // "|| '{}' because SyntaxError: Unexpected token u in JSON
    this.state = JSON.parse(localStorage.isOpened || '{}');
  }

  ngOnChanges(changes: SimpleChanges): void { }
}
