import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TokenStorage} from '../auth/token.storage';
import {of, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable()
export class RequestService {

  constructor(
    protected http: HttpClient,
    private tokenStorage: TokenStorage
  ) {
  }

  static getApiUrl(url) {
    return `${environment.api_url}${url}`;
  }

  protected static handleError(error) {
    switch (error.status) {
      case 404:
        return throwError(error); // Should be instance of Not found error
      case 400:
        return throwError(error); // Should be instance of Bad input error
      default:
        break;
    }
    return throwError(error);
  }

  get(url, data, token = null) {
    return this.sendRequest('GET', url, data, token);
  }

  put(url, data, token = null) {
    return this.sendRequest('PUT', url, data, token);
  }

  post(url, data, token = null) {
    return this.sendRequest('POST', url, data, token);
  }

  patch(url, data, token = null) {
    return this.sendRequest('PATCH', url, data, token);
  }

  delete(url, data, token = null) {
    return this.sendRequest('DELETE', url, data, token);
  }


  sendRequest(method, url, data, token = true) {
    const options = {params: {}, headers: {}};

    if (method === 'GET' || method === 'DELETE') {
      options.params = data;
    }
    if (token) {
      options.headers = new HttpHeaders({Authorization: this.tokenStorage.getToken()});
    }

    if (method === 'GET') {
      return this.http.get(RequestService.getApiUrl(url), options)
        .pipe(
          catchError(err => {
            RequestService.handleError(err);
            return of([]);
          })
        );
    }

    if (method === 'PUT') {
      return this.http.put(RequestService.getApiUrl(url), data, options)
        .pipe(
          catchError(err => {
            RequestService.handleError(err);
            return of([]);
          })
        );
    }

    if (method === 'POST') {
      return this.http.post(RequestService.getApiUrl(url), data, options)
        .pipe(
          catchError(err => {
            RequestService.handleError(err);
            return of([]);
          })
        );
    }

    if (method === 'PATCH') {
      return this.http.patch(RequestService.getApiUrl(url), data, options)
        .pipe(
          catchError(err => {
            RequestService.handleError(err);
            return of([]);
          })
        );
    }

    if (method === 'DELETE') {
      return this.http.delete(RequestService.getApiUrl(url), options)
        .pipe(
          catchError(err => {
            RequestService.handleError(err);
            return of([]);
          })
        );
    }
  }
}
