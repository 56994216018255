import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {LoggedUserService} from '../auth/logged-user.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AuthService, FacebookLoginProvider, GoogleLoginProvider, LinkedInLoginProvider, SocialUser} from 'angularx-social-login';
import {MatSnackBar} from '@angular/material';
import {TokenStorage} from '../auth/token.storage';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  hide = true;
  loginForm: FormGroup;

  private user: SocialUser;
  private loggedIn: boolean;

  @ViewChild('focusField', {static: true}) focusField: any;


  constructor(
    private loggedUserService: LoggedUserService,
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar,
    private tokenStorage: TokenStorage,
    private http: HttpClient) {
  }

  // getApiUrl(url) {
  //   return `${environment.api_url}${url}`;
  // }

  ngOnInit() {
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required)
    });
    this.focusField.nativeElement.focus();
  }

  login() {
    this.loggedUserService.login(this.loginForm.value).subscribe(
      (res: any) => {
        this.router.navigate(['']);
      },
      err => {
        this.snackBar.open('An error occurred', '', {duration: 2000});
      });
  }

  // socialLogin(url, data) {
  //   return this.http.post(this.getApiUrl(url), data);
  // }
  // signInWithGoogle(): void {
  //   this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  // }
  //
  // signInWithFB(): void {
  //   this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  // }
  //
  // signInWithLinkedIn(): void {
  //   this.authService.signIn(LinkedInLoginProvider.PROVIDER_ID);
  // }


  // userLoggedIn(response) {
  //   this.loggedUserService.storeUserData(response);
  //   this.router.navigate(['']);
  // }
  //
  // userLoginError(response) {
  //   try {
  //     if (response.error && response.error[`id_error`] === 20) {
  //       this.snackBar.open('Please enter valid credentials', '', {duration: 2000});
  //     } else {
  //       this.snackBar.open('An error occurred', '', {duration: 2000});
  //     }
  //   } catch (err) {
  //     console.log('Error load login error', err);
  //     console.log('Initial error', response);
  //   }
  // }


}
