//   ngOnInit() {
//     this.route.data.subscribe(data => {   // ovo je bilo uz resolver
//       this.invoices = data.invoices;
//     });
//   }
//
//   // openDetails(id) {
//   //   const row = document.getElementById(`${id}`) as any;
//   //   row.classList.toggle('invoice-details');
//   // }
// }
import {animate, group, state, style, transition, trigger} from '@angular/animations';

import {CustomerService} from '../../services/customer.service';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TableService} from '../../services/table.service';
import {Subscription} from 'rxjs';
import {Overlay, CdkOverlayOrigin, OverlayConfig} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
  providers: [TableService],
  animations: [
    // trigger('animationOption', [
    //   transition(':enter', [
    //     style({backgroundColor: 'white', height: '0px'}),
    //     animate('0.3s  ease-out')
    //   ]),
    //   transition(':leave', [
    //     animate(('.2s  ease-in-out'), style({backgroundColor: '#f9f9f9', height: 'auto'}))
    //   ]),
    //   state('*', style({backgroundColor: '#f9f9f9', overflow: ''})),
    // ]),
  ]
})

export class InvoicesComponent implements OnInit, OnDestroy{
  detailsWidth: number;
  show: boolean;
  invoices: any;
  dataSource = [];

  dataRange = {
    from: '2018-09-03',
    to: '2018-09-05'
  };

  // queryParamsSubscription: Subscription;

  displayedColumns = ['date', 'time', 'amount', 'actions'];

  yourFormControl = new FormControl();  // default new Date() --> -7 dayes

  constructor(private route: ActivatedRoute,
              public tableService: TableService,
              private customerService: CustomerService,
              public overlay: Overlay) {
  }

  // @ViewChild(CdkOverlayOrigin, {static: true}) _overlayOrigin: CdkOverlayOrigin;
  // open = false;

  ngOnInit() {
    // const id = this.route.snapshot.params.id;
    // this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
    //   this.tableService.getData(`/api/users/${id}/invoices`,
    //     {
    //       defaultTableParams: params,
    //       responseDataTitle: 'invoices'
    //     });
    // });
    this.invoicesDatarange(this.dataRange);
  }

  ngOnDestroy(): void {
    // this.queryParamsSubscription.unsubscribe();
  }

  showDetails() {
    const el = document.getElementById('invoice-table') as any;
    this.detailsWidth = el.clientWidth;
  }

  onResize() {
    const el = document.getElementById('invoice-table') as any;
    this.detailsWidth = el.clientWidth;
  }

  invoicesDatarange(data) {
    const id = this.route.snapshot.params.id;
    this.tableService.getInvoices(id, data).subscribe(res => {
      this.dataSource = res;
      this.show = true;
    });
    // this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
    //   this.tableService.getData(`/api/users/${id}/invoices`,
    //     {
    //       defaultTableParams: params,
    //       responseDataTitle: 'invoices'
    //     })
    //   ;
    // });
  }

  // changeState() {
  //   this.currentState = this.currentState === 'initial' ? 'final' : 'initial';
  // }
}
