import {Injectable} from '@angular/core';
import {TokenStorage} from './token.storage';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {RequestService} from '../services/requests.service';
import {map} from 'rxjs/operators';
import {TokenModel} from '../shared/models/token.model';
import {LoginModel} from '../shared/models/login.model';

@Injectable({
  providedIn: 'root'
})
export class LoggedUserService {

  tokenStorage: TokenStorage;
  token: string;

  id: string;

  constructor(private http: HttpClient
  ) {
    this.tokenStorage = new TokenStorage();
    this.token = this.tokenStorage.getToken();
  }

  login(login: LoginModel) {
    return this.http.post<TokenModel>(RequestService.getApiUrl(
      'user/login'),
      {},
      {
        params: {
          username: login.username,
          password: login.password
        }
      }
    ).pipe(map(res => {
      this.tokenStorage.saveToken(res.token);
    }));
  }


  isAuthenticated() {
    return !!this.tokenStorage.getToken();
  }

  // getHostUrl(url) {
  //   return `${environment.host_url}${url}`;
  // }
  //
  // // attemptAuth(username: string, password: string): Observable<any> {
  // //   const credentials = {username, password};
  // //   return this.http.post(this.getHostUrl('/user/login'), credentials);
  // // }

  // isAuthenticated() {
  //   const promise = new Promise(
  //     (resolve) => {
  //       resolve(this.tokenStorage.getToken() !== null);
  //     }
  //   );
  //   return promise;
  // }
  //
  // logout() {
  //   this.tokenStorage.signOut();
  // this.loggedIn = false;
  // }

  logout() {
    return this.http.post(
      RequestService.getApiUrl(`user/logout`),
      {}
    ).pipe(map(() => {
      this.tokenStorage.signOut();
    }));
  }

  // storeUserData(response) {
  //   if (response.token) {
  //     this.tokenStorage.saveToken(response.token);
  //   }
  //   if (response.id) {
  //     this.id = response.id;
  //   }
  //   if (response.username) {
  //     this.username = response.username;
  //   }
  //   if (response.first_name) {
  //     this.firstName = response.first_name;
  //   }
  //   if (response.last_name) {
  //     this.lastName = response.last_name;
  //   }
  // }
  //
  // checkUser() {
  //   const header = {headers: new HttpHeaders({Authorization: this.tokenStorage.getToken()})};
  //   return this.http.get(this.getHostUrl('/user/login'), header);
  // }
}
