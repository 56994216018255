import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MediatorService} from './mediator.service';
import {RequestService} from './requests.service';
import {LoggedUserService} from '../auth/logged-user.service';
import {TableDataSource} from './table-data-source';
import {FormControl, FormGroup} from '@angular/forms';
import {TokenStorage} from '../auth/token.storage';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TableService {
  dataSource: TableDataSource;
  data = [];

  paramData = {};
  customParams = {};

  pageSize = 10;
  pageIndex = 0;
  total = 0;

  search = '';
  offset = 0;
  limit = 50;
  orderBy = '';

  // tslint:disable:variable-name
  from_date: any;
  to_date: any;
  // tslint:enable:variable-name

  searchForm: FormGroup;
  isLoadMoreVisible = true;
  isContentVisible = false;
  isErrorMessageVisible = false;

  currentRoute: ActivatedRoute;

  constructor(public router: Router,
              public mediator: MediatorService,
              public api: RequestService,
              private token: TokenStorage,
              public loggedUser: LoggedUserService,
              private http: HttpClient) {
    this.dataSource = new TableDataSource(this.api, this.token, this.loggedUser);
    this.searchForm = new FormGroup({searchInput: new FormControl()});
  }

  getData(url, {responseDataTitle = 'data', hasDefaultTableParams = true, defaultTableParams = {}, customParams = {}} = {}) {
    this.customParams = customParams;

    if (hasDefaultTableParams) {
      this.paramData = this.prepareParams(defaultTableParams, customParams);
      this.setParams(this.paramData, this.customParams);
      this.pageIndex = this.paramData[`offset`] / this.paramData[`limit`];
      this.paramData[`search`] !== null ? this.mediator.inputValue = this.paramData[`search`] : this.mediator.inputValue = this.search;
    } else {
      this.paramData = this.customParams;
    }

    return this.dataSource.loadData(url, this.paramData, responseDataTitle);
  }

  getInvoices(customerId, data) {
    return this.http.get<any>(
      `${environment.api_url}api/users/${customerId}/invoices`,
      {
        params: {
          from_date: data.from,
          to_date: data.to
        }
      }
    ).pipe(map(res => res.invoices));
  }


  setTotal(total) {
    this.total = total;
    this.pageSize = this.limit;
    this.isLoadMoreVisible = true;

    if (this.total < this.limit) {
      this.pageSize = this.total;
      this.isLoadMoreVisible = false;
    }
    if (this.total === this.limit) {
      this.isLoadMoreVisible = false;
    }
    if (total === 0) {
      this.pageSize = 0;
    }
  }

  setParams(params, customParams) {
    this.limit = params.limit;
    this.offset = params.offset;
    this.search = params.search;
    this.orderBy = params.orderBy;
    this.customParams = customParams;
    // this.from_date = params.from;
    // this.to_date = params.to;
  }

  prepareParams(params, customParams = {}) {
    const data = {
      limit: params.limit != null ? +params.limit : this.limit,
      offset: params.offset != null ? +params.offset : this.offset,
      search: params.search != null ? params.search : this.search,
      orderBy: params.orderBy != null ? params.orderBy : this.orderBy,
      // from_date: params.from != null ? params.from : this.from_date,
      // to_date: params.to != null ? params.to : this.to_date
    };
    for (const param in customParams) {
      if (customParams.hasOwnProperty(param)) {
        data[param] = customParams[param];
      }
    }
    return data;
  }

  // updateCustomParams(params) {
  //   this.customParams = params;
  // }

  setQueryParams(params?) {
    let parameters = {};

    if (params !== null) {
      parameters = this.prepareParams(this.paramData, this.customParams);
    }

    const url = [];
    this.currentRoute.snapshot.pathFromRoot.forEach(route => {
      if (route.url.length > 0) {
        route.url.forEach(segment => url.push(segment.path));
      }
    });

    this.router.navigate(url, {queryParams: parameters});
  }

  setDefaultQueryParams() {
    this.paramData = this.prepareParams({
      limit: this.limit,
      offset: this.offset,
      search: this.search,
      orderBy: this.orderBy,
    }, this.customParams);
    this.setQueryParams(this.paramData);
  }

  resetQueryParams() {
    this.setQueryParams(null);
  }

  // setOrderBy(columnName) {
  //   this.orderBy = columnName;
  //   this.offset = 0;
  //   this.setDefaultQueryParams();
  // }
  //
  // filter(value) {
  //   if (value !== null && value.length >= 2) {
  //     this.pageIndex = 0;
  //     this.search = value;
  //     this.offset = this.pageIndex * this.pageSize;
  //     this.setDefaultQueryParams();
  //   } else {
  //     this.resetSearch();
  //   }
  // }

  resetSearch() {
    // this.mediator.savedRouteParams = {};
    // this.mediator.savedBreadcrumb = {};
    this.search = '';
    this.paramData = this.prepareParams({
      limit: this.limit,
      offset: this.offset,
      search: this.search,
      orderBy: this.orderBy,
    }, this.customParams);
    this.resetQueryParams();
  }

  // resetTableData() {
  //   this.search = '';
  //   this.paramData = this.prepareParams({
  //     limit: this.limit,
  //     offset: this.offset,
  //     search: this.search,
  //     orderBy: this.orderBy,
  //   },  this.customParams);
  //   this.data = [];
  // }

  changePage(e) {
    this.offset = e.pageIndex * this.pageSize;
    this.setDefaultQueryParams();
  }

  // showMore() {
  //   if (this.limit + 10 > this.total) {
  //     this.limit = this.total;
  //     this.setDefaultQueryParams();
  //   } else {
  //     this.limit += 10;
  //     this.setDefaultQueryParams();
  //   }
  // }
}
