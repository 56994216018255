import {Injectable} from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

import {EMPTY, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {LoggedUserService} from '../../auth/logged-user.service';
import {Router} from '@angular/router';
import {TokenStorage} from '../../auth/token.storage';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {


  constructor(
    private loggedUserService: LoggedUserService,
    private tokenStorage: TokenStorage,
    private router: Router
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let requestHeaders = req.headers;
    const token = this.tokenStorage.getToken();

    if (token) {
      requestHeaders = requestHeaders.set('Authorization', token);
    }

    const cloned = req.clone({
      headers: requestHeaders
    });

    return next.handle(cloned).pipe(catchError((err: HttpEvent<any>) => this.handleError(err)));
  }

  private handleError(response: HttpEvent<any>) {
    if (
      response instanceof HttpErrorResponse &&
      response.status === 401 &&
      this.loggedUserService.isAuthenticated() ||
      response instanceof HttpErrorResponse &&
      response.status === 403 &&
      this.loggedUserService.isAuthenticated()
    ) {
      this.tokenStorage.signOut();
      this.router.navigate(['login']);
      return EMPTY;
    }
    return throwError(response);
  }
}
