import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatButtonModule,
  MatDatepickerModule,
  MatGridListModule,
  MatNativeDateModule,
  DateAdapter,
  MatChipsModule,
  MAT_DATE_LOCALE
} from '@angular/material';
import { MatCardModule } from '@angular/material';
import { MatInputModule } from '@angular/material';
import { MatListModule } from '@angular/material';
import { MatIconModule } from '@angular/material';
import { MatSidenavModule } from '@angular/material';
import { MatMenuModule } from '@angular/material';
import { MatTableModule } from '@angular/material';
import { MatTooltipModule } from '@angular/material';
import { MatTabsModule } from '@angular/material';
import { MatSelectModule } from '@angular/material';
import { MatPaginatorModule } from '@angular/material';
import { MatSortModule } from '@angular/material';
import { MatCheckboxModule } from '@angular/material';
import { MatRadioModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatProgressSpinnerModule} from '@angular/material';
import {MatDialogModule} from '@angular/material';
import {MatSnackBarModule} from '@angular/material';
import {MatAutocompleteModule} from '@angular/material';
import {MatSliderModule} from '@angular/material/slider';
import {MatToolbarModule} from '@angular/material';

import {MatStepperModule} from '@angular/material/stepper';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import {Platform, PlatformModule} from '@angular/cdk/platform';

import {MatTreeModule} from '@angular/material/tree';
import {CdkTreeModule} from '@angular/cdk/tree';

@NgModule({
  imports: [
    CommonModule,
    PlatformModule
  ],
  exports: [
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatListModule,
    MatIconModule,
    MatSidenavModule,
    MatMenuModule,
    MatTableModule,
    MatTooltipModule,
    MatTabsModule,
    MatSelectModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatDialogModule,
    MatRadioModule,
    BrowserAnimationsModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatSliderModule,
    MatStepperModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatChipsModule,
    PlatformModule,
    CdkTreeModule,
    MatTreeModule,
    OverlayModule,
    PortalModule,
    MatToolbarModule
  ],
  declarations: [],
  providers: [
    // {
    //   provide: DateAdapter, useClass: DateFormat, deps: [MAT_DATE_LOCALE, Platform]
    // }
  ]
})
export class MaterialModule { }
