import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {DataSource} from '@angular/cdk/table';
import {CollectionViewer} from '@angular/cdk/collections';
import {RequestService} from './requests.service';
import {LoggedUserService} from '../auth/logged-user.service';
import {TokenStorage} from '../auth/token.storage';

export class TableDataSource implements DataSource<any> {
  public dataSourceSubject = new BehaviorSubject<any>(null);
  public data$ = this.dataSourceSubject.asObservable();

  public miscDataSubject = new BehaviorSubject<any>(null);
  public miscData$ = this.miscDataSubject.asObservable();

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  private countSubject = new BehaviorSubject<number>(0);
  public counter$ = this.countSubject.asObservable();

  responseDataTitle = '';

  requestSubscription: Subscription;


  constructor(private api: RequestService,
              private token: TokenStorage,
              private loggedUser: LoggedUserService) {
  }

  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    this.dataSourceSubject = new BehaviorSubject<any>(null);
    this.miscDataSubject = new BehaviorSubject<any>(null);
    this.data$ = this.dataSourceSubject.asObservable();
    this.miscData$ = this.miscDataSubject.asObservable();
    return this.dataSourceSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSourceSubject.complete();
    this.miscDataSubject.complete();
    this.requestSubscription.unsubscribe();
  }

  loadData(url, params = {}, responseDataTitle = 'data') {
    this.responseDataTitle = responseDataTitle;
    this.loadingSubject.next(true);

    this.requestSubscription = this.api.get(url, params, this.token.getToken()).subscribe(response => {
      this.dataSourceSubject.next(response[this.responseDataTitle]);

      const miscData = {};
      for (const prop in response) {
        if (response.hasOwnProperty(prop) && prop !== 'data' && prop !== 'total') {
          miscData[prop] = response[prop];
        }
      }

      if (Object.keys(miscData).length > 0) {
        this.miscDataSubject.next(miscData);
      }
      this.countSubject.next(response['total']);
      this.loadingSubject.next(false);
    }, error => {
      this.dataSourceSubject.next([]);
      this.countSubject.next(0);
      this.loadingSubject.next(true);
    });
  }
}
