import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Chart} from 'chart.js';
import 'chartjs-plugin-datalabels';
import 'chartjs-plugin-doughnutlabel';

@Component({
  selector: 'app-promotion-dashboard',
  templateUrl: './promotion-dashboard.component.html',
  styleUrls: ['./promotion-dashboard.component.scss']
})
export class PromotionDashboardComponent implements AfterViewInit {
  total = '349.000rsd';
  date = new Date();

  //
  // public doughnutChartOptions = {
  //   responsive: true,
  //   maintainAspectRatio: false,
  //   cutoutPercentage: 70,
  //   doughnutPosition: 'left',
  //   legend: {position: 'right'}
  // };
  // public doughnutChartLabels = ['Mlecni proizvodi', 'Slatikisi', 'Alkoholna pica',
  // 'Meso', 'Mesni proizvodi', 'Bezalkoholna pica', 'Pivo'];
  // public doughnutChartType = 'doughnut';
  // public doughnutChartLegend = true;
  // public doughnutChartData = [
  //   {
  //     data: [2065, 1059, 1180, 4581, 2356, 1155, 1299], label: 'Kupovina u 2019',
  //     backgroundColor: [
  //       '#FFCE4F',
  //       '#8BCFFB',
  //       '#B87CE8',
  //       '#6CB76A',
  //       '#FF7773',
  //       'rgba(255,99,132,1)',
  //       'rgba(54, 162, 235, 1)'
  //     ],
  //   },
  // ];
  //

  constructor() {
  }

  ngAfterViewInit() {
  }
}
