import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from './material/material.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoginComponent} from './login/login.component';
import {ForgotComponent} from './forgot/forgot.component';
import {
  AuthServiceConfig,
  FacebookLoginProvider,
  GoogleLoginProvider,
  LinkedInLoginProvider,
  SocialLoginModule
} from 'angularx-social-login';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {LayoutComponent} from './layout/layout.component';
import {SideMenuComponent} from './layout/side-menu/side-menu.component';
import {InvoicesComponent} from './layout/invoices/invoices.component';
import {InvoicesChartComponent} from './layout/invoices/invoices-chart/invoices-chart.component';
import {ChartsModule} from 'ng2-charts';
import {AuthInterceptor} from './shared/interceptors/auth.interceptor';
import {CalendarModule} from 'primeng/primeng';
import { PromotionDashboardComponent } from './layout/promotion-dashboard/promotion-dashboard.component';
import {TablePaginatorComponent} from './shared/components/table-paginator.component';
import {MediatorService} from './services/mediator.service';
import { CustomerTableComponent } from './layout/customer-table/customer-table.component';
import {RequestService} from './services/requests.service';
import {DateRangePickerComponent} from './shared/components/daterangepicker/range-picker.component';
import { LineAreaChartComponent } from './charts/line-area-chart/line-area-chart.component';
import { DoughnutChartComponent } from './charts/doughnut-chart/doughnut-chart.component';
import { VerticalBarSimpleChartComponent } from './charts/vertical-bar-simple-chart/vertical-bar-simple-chart.component';
import { HalfDoughnutChartComponent } from './charts/half-doughnut-chart/half-doughnut-chart.component';

const config = new AuthServiceConfig([
  // {
  //   id: GoogleLoginProvider.PROVIDER_ID,
  //   provider: new GoogleLoginProvider("Google-OAuth-Client-Id")
  // },
  // {
  //   id: FacebookLoginProvider.PROVIDER_ID,
  //   provider: new FacebookLoginProvider("Facebook-App-Id")
  // },
// {
//   id: LinkedInLoginProvider.PROVIDER_ID,
//   provider: new LinkedInLoginProvider("LinkedIn-client-Id", false, 'en_US')
// }
]);

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotComponent,
    ResetPasswordComponent,
    LayoutComponent,
    SideMenuComponent,
    InvoicesComponent,
    InvoicesChartComponent,
    PromotionDashboardComponent,
    TablePaginatorComponent,
    CustomerTableComponent,
    DateRangePickerComponent,
    LineAreaChartComponent,
    DoughnutChartComponent,
    VerticalBarSimpleChartComponent,
    HalfDoughnutChartComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    AppRoutingModule,
    SocialLoginModule,
    ChartsModule,
    CalendarModule
  ],
  providers: [
    MediatorService,
    RequestService,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig,
    },
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
