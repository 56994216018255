import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MediatorService {

  searchValueSubject = new BehaviorSubject<any>(null);
  searchValue$ = this.searchValueSubject.asObservable();

  inputValue = '';

  showLoader = false;

  constructor() { }
}
