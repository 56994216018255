import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-chart-invoices',
  templateUrl: './invoices-chart.component.html',
  styleUrls: ['./invoices-chart.component.scss']
})
export class InvoicesChartComponent implements OnInit {
  constructor() {
  }

  public barChartOptions = {
    scaleShowVerticalLines: false,

    scales: {
      xAxes: [{
        display: false,
        barThickness: 44,  // number (pixels) or 'flex'
        maxBarThickness: 44, // number (pixels)
        ticks: {
          // fontSize: 30
        }
      }],
    },
    responsive: true,
    maintainAspectRatio: false
  };
  public barChartLabels = ['Mlecni proizvodi', 'Slatikisi', 'Alkoholna pica', 'Meso', 'Mesni proizvodi', 'Bezalkoholna pica', 'Pivo'];
  public barChartType = 'bar';
  public barChartLegend = false; // headline - label: 'Kupovina u 2019...'
  public barChartData = [
    {
      data: [2065, 1059, 1180, 2581, 2356, 1155, 1299], label: 'Kupovina u 2019',
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)'
      ],
      borderColor: [
        'rgba(255,99,132,1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 1,
    },
  ];

  ngOnInit() {
  }
}
