import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {LoggedUserService} from './logged-user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {


  constructor(
    private loggedUserService: LoggedUserService,
    private router: Router
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    if (!this.loggedUserService.isAuthenticated()) {
      this.router.navigate(['login']);
    }
    return true;
  }
}

// import {Observable} from 'rxjs';
//
// @Injectable({
//   providedIn: 'root'
// })
// export class AuthGuardService {
//
//   constructor(private loggedUser: LoggedUserService, private router: Router) { }
//
//   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
//     return new Promise((resolve) => {
//       this.loggedUser.checkUser().subscribe(
//         r => {
//           this.loggedUser.storeUserData(r);
//           resolve(true);
//         },
//         err => {
//           this.loggedUser.logout();
//           this.router.navigate(['login']);
//           resolve(false);
//         }
//       );
//     });
//   }
//
//   canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
//     return this.canActivate(childRoute, state);
//   }
// }

