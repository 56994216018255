import {Component, OnInit} from '@angular/core';
import {Chart} from 'chart.js';

@Component({
  selector: 'app-vertical-bar-simple-chart',
  templateUrl: './vertical-bar-simple-chart.component.html',
  styleUrls: ['./vertical-bar-simple-chart.component.scss']
})
export class VerticalBarSimpleChartComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
    const canvasBar = document.getElementById(('canvasBar')) as HTMLCanvasElement;
    // Chart.defaults.global.defaultFontColor = 'transparent';   // daje svim graficima boju ne samo u ovoj komponenti

    const canvasBarChart = new Chart(canvasBar, {
      type: 'bar',
      data: {
        // tslint:disable-next-line:max-line-length
        labels: ['8h', '8:30h', '9h', '9:30h', '10h', '10:30h', '11h', '11:30h', '12h', '12:30h', '13h', '13:30h', '14h', '14:30h', '15h', '15:30h'],
        datasets: [
          {
            label: 'Daily sales (rsd)',
            data: [50000, 70000, 90000, 120000, 110000, 130000, 70000, 90000, 100000, 60000, 70000, 90000, 120000, 70000, 110000, 100000],
            fill: false,
            backgroundColor: '#8BCFFB',
            datalabels: {
              display: false    //  krije vrednosti u "bar"-ovima
            }
          }
        ]
      },
      options: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        scales: {
          yAxes: [{
            display: false,   // krije y osu
            ticks: {
              beginAtZero: true
            }
          }],
          xAxes: [{
            display: false,   // krije vrednosti na x osi
            barThickness: 14,  // number (pixels) or 'flex',
          }]
        },
      },
    });

  }
}
