import {Component, OnInit} from '@angular/core';
import {Chart, ChartTooltipOptions} from 'chart.js';

@Component({
  selector: 'app-line-area-chart',
  templateUrl: './line-area-chart.component.html',
  styleUrls: ['./line-area-chart.component.scss']
})
export class LineAreaChartComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {

    const lineArea = document.getElementById('myChart') as HTMLCanvasElement;
    const barHorizontal = document.getElementById(('bar-chart-horizontal')) as HTMLCanvasElement;

    const colors = {
      lightBlue: {
        fill: '#6fccdd',
        stroke: '#6fccdd',
      },
      darkBlue: {
        fill: '#92bed2',
        stroke: '#3282bf',
      },
      purple: {
        fill: '#8fa8c8',
        stroke: '#75539e',
      },
    };

    const available = [34000, 44000, 33000, 24000, 25000, 28000, 25000];
    const availableForExisting = [16000, 13000, 25000, 33000, 40000, 33000, 45000];
    const unavailable = [5000, 9000, 10000, 9000, 18000, 19000, 20000];
    const xData = ['Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May'];

    const lineAreaChart = new Chart(lineArea, {
      type: 'line',
      data: {
        labels: xData,
        datasets: [{
          label: 'Unavailable',
          fill: true,
          backgroundColor: colors.purple.fill,
          // pointBackgroundColor: colors.purple.stroke,  //  tacke na linijama
          borderColor: colors.purple.fill,
          // pointHighlightStroke: colors.purple.stroke,  // ovo su tacke na mestima gde su bregovi *stroke* (vrednosti u zadatom mestu)
          data: unavailable,

        }, {
          label: 'Available for Existing',
          fill: true,
          backgroundColor: colors.darkBlue.fill,
          borderColor: colors.darkBlue.fill,
          data: availableForExisting,
        }, {
          label: 'Available',
          fill: true,
          backgroundColor: colors.lightBlue.fill,
          borderColor: colors.lightBlue.stroke,
          data: available,
        }]
      },
      options: {
        responsive: false,
        // Can't just just `stacked: true` like the docs say
        scales: {
          yAxes: [{
            stacked: false,   // grafici su jedan iza drugog (false je inace default)
          }],
          xAxes: [{
            display: true     // krije vrednosti na x osi
          }]
        },
        animation: {
          duration: 750,
        },
        legend: {
          display: false,     // krije legendu (od chart.js)
        },
        plugins: {
          datalabels: {
            display: false,   // krije brojeve na tackama *stroke* (na mestima gde su bregovi (vrednosti u zadatom mestu))
          },
        },
        elements: {
          point: {
            radius: 0         // krije tacke *stroke* liniji grafika
          }
        }
      },
    });

    const horizontalBarChart = new Chart(barHorizontal, {
      type: 'horizontalBar',
      data: {
        labels: ['Promotion 1', 'Promotion 2', 'Promotion 3'],
        datasets: [
          // {
          // label: 'Promotion',
          // backgroundColor: [colors.lightBlue.fill, colors.darkBlue.fill, colors.purple.fill],
          // data: [2478, 3267, 1734]
          // }
          {
            label: 'Promotion 1',
            fill: false, data: [2478, 3267, 1834],
            backgroundColor: [colors.lightBlue.fill, colors.darkBlue.fill, colors.purple.fill]
          },
          {fill: false, data: [4000, 4000, 4000], backgroundColor: '#d9d9d9'}
        ]
      },
      options: {
        legend: {display: false},    // krije legendu (nazive tabele sa bojama)
        title: {
          display: false,
          text: 'Top promotion performance'
        },
        scales: {
          yAxes: [{
            display: false,   // krije y osu
            barThickness: 10,  // number (pixels) or 'flex'
            stacked: true,
          }],
          xAxes: [{
            display: false,   // krije vrednosti na x osi
          }]
        },
        plugins: {            // plugin koji omogucava prikazivanje 100% grafika (bar)
          stacked100: {
            enable: true,
          },
          datalabels: {
            display: false    // krije brojeve u grafiku (u bar-u)
          }
        }
      }
    });
  }
}

// pogledati u node_modules/@types/chart.js/index.d.ts  za sve opcije chart.js-a
