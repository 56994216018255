import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(
    private http: HttpClient,
  ) {
  }

  // search(term: string) {
  //   return this.http.get<any>(
  //     `${environment.api_url}api/users`, {
  //       params: {
  //         search: term
  //       }
  //     }
  //   ).pipe(map(res => res.users as any[]));
  // }

  // getInvoices(customerId, data) {                      // prebacena metoda u table.service.ts
  //   return this.http.get<any>(
  //     `${environment.api_url}api/users/${customerId}/invoices`,
  //     {
  //       params: {
  //         from_date: data.from,
  //         to_date: data.to
  //       }
  //     }
  //   ).pipe(map(res => res.invoices));
  // }
}
