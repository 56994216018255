import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MediatorService} from '../../services/mediator.service';
import {TableService} from '../../services/table.service';
import {Subject, Subscription} from 'rxjs';
import {mergeMap, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-customer-table',
  templateUrl: './customer-table.component.html',
  styleUrls: ['./customer-table.component.scss'],
  providers: [TableService],
})
export class CustomerTableComponent implements OnInit, AfterViewInit, OnDestroy {

  queryParamsSubscription: Subscription;
  // searchSubscription: Subscription;

  displayedColumns = ['name', 'phone', 'balance', 'address', 'actions'];

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              public mediator: MediatorService,
              public tableService: TableService) {
  }

  ngOnInit() {
    // this.tableService.currentRoute = this.route;

    this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe(params => {
      this.tableService.getData(`/api/users`, {defaultTableParams: params, responseDataTitle: 'users'});
    });

    // this.searchSubscription = this.mediator.searchValue$.subscribe(value => {
    //   if (value != null) {
    //     this.tableService.filter(value);
    //   }
    // });
  }
  //
  ngAfterViewInit(): void {
    this.tableService.dataSource.data$.pipe(
      mergeMap(data => {
        if (data != null) {
          this.tableService.data = data;
        }
        return this.tableService.dataSource.counter$;
      }),
      mergeMap(count => {
        this.tableService.setTotal(count);
        return this.tableService.dataSource.loading$;
      }), takeUntil(this.destroy$)
    ).subscribe(isLoading => this.mediator.showLoader = isLoading, err => console.log('error'));
  }

  ngOnDestroy(): void {
    this.queryParamsSubscription.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    // this.searchSubscription.unsubscribe();
  }

  getInv(id) {
    this.router.navigate([`invoices/${id}`]);
  }
}
