import {Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {CustomerService} from '../services/customer.service';

@Injectable()
export class InvoicesResolver implements Resolve<any> {


  constructor(
    private customerService: CustomerService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    // const id = route.params.id;
    // return this.customerService.getInvoices(id);
  }
}
