import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Chart} from 'chart.js';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent implements OnInit {

  @ViewChild('canvasDoughnut', {static: true}) canvas: ElementRef<HTMLCanvasElement>;

  total: number;

  labelColors = [
    '#FFCE4F',
    '#8BCFFB',
    '#B87CE8',
    '#6CB76A',
    '#FF7773',
  ];

  labelsValue = [
     20, 10, 15, 40, 15
  ];

  // sum = this.labelsValue.reduce((sum, current) => sum + current, 0);     // prikazuje sumu u sredini doughnut-a

  constructor() {
  }

  ngOnInit() {
    const ctx = this.canvas.nativeElement.getContext('2d');

    const myChart3 = new Chart(ctx, {
      type: 'doughnut',
      data: {
        datasets: [{
          data: this.labelsValue,
          backgroundColor: this.labelColors,
          label: 'Dataset 1'
        }],
        labels: [
          'Mlecni proizvodi',
          'Meso',
          'Slatkisi',
          'Povrce',
          'Hemija'
        ]
      },
      options: {
        cutoutPercentage: 70,
        responsive: true,
        legend: {
          display: false,             // krije legendu (nazive tabele sa bojama)
          position: 'right',
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        plugins: {
            pieceLabel: {
              mode: 'percentage'
            },
          doughnutlabel: {
            labels: [
              {
                text: '',              // this.sum,
                font: {
                  size: '12',
                  units: 'px',
                  family: 'Open sans',
                  // style: 'italic',
                  // weight: 'bold',
                },
                color: 'rgba(0,0,0,.54)',
              }
            ]
          }
        }
      }
    });
  }
}
