import {Component, OnInit} from '@angular/core';
import {LoggedUserService} from '../auth/logged-user.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  active = true;

  constructor(
    private loggedUserService: LoggedUserService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.active = JSON.parse(localStorage.isOpened || '{}');  // "|| '{}' because SyntaxError: Unexpected token u in JSON

  }

  logout() {
    this.loggedUserService.logout().subscribe(() => {
      this.router.navigate(['login']);
    });
  }

  showSidebar() {
    this.active = !this.active;
    localStorage.isOpened = this.active;
  }
}
