import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {ForgotComponent} from './forgot/forgot.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {LayoutComponent} from './layout/layout.component';
import {InvoicesComponent} from './layout/invoices/invoices.component';
import {InvoicesResolver} from './shared/invoices.resolver';
import {InvoicesChartComponent} from './layout/invoices/invoices-chart/invoices-chart.component';
import {AuthGuard} from './auth/auth.guard';
import {PromotionDashboardComponent} from './layout/promotion-dashboard/promotion-dashboard.component';
import {CustomerTableComponent} from './layout/customer-table/customer-table.component';

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'forgot', component: ForgotComponent},
  {path: 'reset', component: ResetPasswordComponent},
  {
    path: '', component: LayoutComponent, canActivate: [AuthGuard], children: [
      {path: '', component: CustomerTableComponent},
      {path: 'promotion-dashboard', component: PromotionDashboardComponent},
      {
        path: `invoices/:id`, component: InvoicesComponent,
        children: [
          {path: '', component: InvoicesChartComponent}
        ],
        resolve: {invoices: InvoicesResolver}
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [InvoicesResolver]
})
export class AppRoutingModule {
}
